<template>
    <Dashboard />
</template>
<script>
import { LOGIN } from '../../routes';
import Dashboard from '../../components/author/dashboard/Home';
export default {
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: LOGIN}); //TODO check auth with globar guard
            return;
        }
    },
    components: {
        Dashboard,
    }
}
</script>