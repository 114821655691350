<template>
    <v-card class="mx-auto transparent" flat>
      <!--Error box alert-->
      <v-row v-if="error" style="margin:10px;">
        <v-col cols="12" md="12" sm="12">
          <v-alert type="error">{{error.error}}</v-alert>
        </v-col>
      </v-row>
      <v-overlay :value="processingProfileData">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      <!--Form-->
      <v-form style="width: 100%;" ref="form">
        <v-card class="pa-8 secondary">
          <v-card-text>
            <v-row class="mb-4" align="center">
              <v-avatar color="grey" class="mr-4"></v-avatar>
              <strong class="title">Dados Pessoais</strong>
              <div class="flex-grow-1"></div>
              <v-btn icon>
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>

          <v-row>
            <v-col md="12" sm="12" xl="12">
              <v-file-input
                dense
                counter
                label="imagem do utilizador"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Escolha uma foto"
                prepend-icon="mdi-paperclip"
                hint="1000 x 1000 pixels. PNG ou JPG com tamanho no máximo 2MB"
                persistent-hint
                outlined
                :rules="rules.file2MB"
                :show-size="1000"
                v-model="info.photo"
              ></v-file-input>
              
            </v-col>

            <v-col md="6" sm="6" xl="6">
              <v-text-field
                dense
                v-model="info.firstName"
                outlined
                :counter="30"
                :rules="rules.firstname"
                label="nome"
                :error-messages="errors.collect('firstName')"
                data-vv-name="firstName"
                required
              ></v-text-field>
            </v-col>

            <v-col md="6" sm="6" xl="6">
              <v-text-field
                dense
                v-model="info.lastName"
                outlined
                label="Apelido"
                :counter="30"
                :rules="rules.lastname"
                :error-messages="errors.collect('lastName')"
                data-vv-name="lastName"
                required
              ></v-text-field>
            </v-col>

            <v-col md="6" sm="6" xl="12">
              <v-text-field
                dense
                outlined
                v-model="info.username"
                :rules="rules.username"
                :counter="150"
                :error-messages="errors.collect('username')"
                label="Username"
                data-vv-name="username"
                required
              ></v-text-field>
            </v-col>

            <v-col md="6" sm="6" xl="6">
                <v-text-field dense
                  v-model="info.email"
                  type="email"
                  outlined
                  :rules="rules.email"
                  :counter="254"
                  data-vv-name="email"
                  label="Endereço e-mail"
                  :error-messages="errors.collect('email')"
                  hint="exemple@kblast.com"
                  persistent-hint
                  prefix="@"
                  required
                  ></v-text-field>
            </v-col>

            <!-- Autocomplete for category -->
            <v-col cols="12">
              <v-autocomplete
                dense
                v-validate="'required'"
                :error-messages="errors.collect('category')"
                data-vv-name="category"
                required
                v-model="info.subcategories"
                :items="subcategories"
                filled
                chips
                outlined
                hint="Campo obrigatório"
                label="Categorias de utilizador"
                item-text="text"
                item-value="id"
                multiple
                prepend-inner-icon="mdi-account-circle"
              >
                <template v-slot:selection="{ item, attrs, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeCategory(item.id)"
                  >{{ item.text }}</v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <template v-if="typeof item !== 'object'">
                    <v-list-item-content v-text="item.text"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.text"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.subtext"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col md="12" sm="12" xl="12" style="margin-top: -35px;">
              <v-btn
                color="primary"
                small
                @click="req_fest_prof = true"
              >
                <span class="black--text">REQUERER PERFIL DE ORGANIZADOR FESTIVAL</span>
              </v-btn>
            </v-col>

            <v-col md="6" sm="6" xl="6">
              <v-autocomplete
                dense
                v-model="info.city"
                :items="address"
                v-validate="'required'"
                :error-messages="errors.collect('address')"
                data-vv-name="address"
                required
                filled
                chips
                outlined
                height="10"
                label="Endereço"
                item-text="text"
                item-value="value"
                prepend-inner-icon="mdi-map-marker"
              >
                <template v-slot:selection="{ item, attrs, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removeAddr"
                  >{{ item.text }}</v-chip>
                </template>
                <template v-slot:item="{ item }">
                  <template v-if="typeof item !== 'object'">
                    <v-list-item-content v-text="item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.text"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.subtext"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col md="6" sm="6" xl="12">
                    <v-text-field dense
                    v-model="info.bio.rel"
                     prefix="https://"
                    outlined
                    :counter="500"
                    label="REEL"
                    hint="Link externo de video"
                    persistent-hint
                    ></v-text-field>
                </v-col>

            <v-col md="12" sm="12" xl="12">
              <v-file-input
                dense
                counter
                label="Foto de Capa"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Escolha uma foto de capa"
                prepend-icon="mdi-paperclip"
                hint="1350 x 360 pixels. PNG ou JPG com tamanho no máximo 2MB"
                persistent-hint
                outlined
                :rules="rules.file2MB"
                :show-size="1000"
                v-model="info.bio.cover"
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="6">
              <label>Citação</label>
              <v-textarea
                solo
                name="input-7-4"
                label="Citação"
                v-model="info.bio.quote"
                :rules="rules.quote"
                :counter="200"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <label>Bio Pessoal</label>
              <div class="black-text">
                <ckeditor 
                  :editor="editor" 
                  v-model="info.bio.personal" :config="editorConfig"></ckeditor>
              </div>
            </v-col>

            <v-col cols="12">
              <label>Bio Professional</label>
              <div class="black-text">
                <ckeditor class="black-text" 
                  :editor="editor" 
                  v-model="info.bio.professional" 
                  :config="editorConfig"></ckeditor>
              </div>
            </v-col>

            <v-col cols="12">
              <v-btn 
                  :loading="processingProfileData"
                  :disabled="processingProfileData"
                  color="primary"
                  @click="updateProfile"><span class="black--text">Guardar alteração de Dados Pessoais</span></v-btn>
              </v-col>
          </v-row>
        </v-card>
      </v-form>
      <!--/Form-->
      <v-snackbar
          v-model="snackbar"
          :color="message ? 'success' : 'error'"
          :multi-line="true"
          :left="true"
          :timeout="6000"
          :bottom="true"
          :vertical="true"
          >
          <p class="white--text">{{ message || error }}</p>
          <v-btn
              dark
              text
              @click="snackbar = false"
          >
              Close
          </v-btn>
      </v-snackbar>
      
      <div class="text-center">
        <v-dialog v-model="req_fest_prof" max-width="500" width="500">
          <v-card class="pa-8">
              <v-card-text>
                  <v-textarea
                      outlined
                      readonly
                      rows="6"
                      label="Outlined textarea"
                      :value="req_fest_proof_txt"
                  ></v-textarea>
                  <v-container
                    class="fill-height"
                    fluid
                  >
                    <v-row
                      align="center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        sm="8"
                        md="10"
                      >
                        <v-file-input
                          accept="application/pdf"
                          placeholder="Escolhe um ficheiro"
                          label="Ficheiro"
                          v-model="req_fest_prof_file.srcFile"
                          show-size
                          outlined
                          clearable
                          :rules="rules.file10MB"
                          dense
                        ></v-file-input>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="8"
                        md="4"
                      >
                        <v-btn
                          color="primary"
                          @click="requestFestProfile"
                        >
                        <span class="black--text">Enviar</span>
                      </v-btn></v-col>           
                    </v-row>
                  </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
      </div>
    </v-card>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


//import { mapState } from 'vuex';
import { mask } from "vue-the-mask";

import _ from "lodash";
import * as file from '../../../utils/file';
import { mapState, mapActions } from 'vuex';

const RULES = {
    required: (field) => v => !!v || `${field} é obrigatório.`,
    max: (field, len) => v => (v && v.length <= len) || `${field} pode ter no máximo ${len} caracteres.`,
    min: (field, len) => v => (v && v.length >= len) || `${field} pode ter no mínimo ${len} caracteres.`,
    username: () => v => /^\w+(-?\w+)*$/.test(v) || `Apenas A-Z,a-z,0-9 e hífen como separador são permitidos.`,
};

export default {
  props: {
    infoData: {
      type: Object,
    },
  },
  $_veeValidate: {
    validator: "new"
  },
  directives: {
    mask
  },
  created() {
    if (this.infoData) {
      this.info = _.cloneDeep(this.infoData);
    }
    this.loadFilters();
  },
  data: () => ({
    req_fest_prof: false,
    req_fest_prof_file: {
      id: null,
      proof: null,
      srcFile: null,
    },
    req_fest_proof_txt: `O requerente deve comprovar que é ou faz parte da entidade organizadora enviando um Comprovativo de Honra assindao e carimbado, em formato PDF (anexando os documentos que a REDE indicar como aptos para a certificação). A documentação será analisada pela Administração da REDE.`,
    snackbar: false,
    message: null,
    error: null,

    rules: {
        firstname: [RULES.required('Primeiro Nome'), RULES.max('Primeiro Nome', 30)],
        lastname: [RULES.required('Apelido'), RULES.max('Apelido', 30)],
        email: [RULES.required('Email'), RULES.max('Email', 254)],
        username: [RULES.required('Username'), RULES.max('Username', 150), RULES.username()],
        quote: [ RULES.max('Citação', 200)],
        file2MB: [file.factoryRule({ totalSize: { max: 2, unit: 'MB'}})],
        file10MB: [file.factoryRule({  totalSize: { max: 10, unit: 'MB' } })]
    },
    info: {
        photo: null,
        email: null,
        firstName: null,
        lastName: null,
        username: null,
        subcategories: [],
        city: null,
        bio: {
            quote: null,
            rel: null,
            cover: null,
            profissional: null,
            personal: null,
        }
    },
    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        //'imageUpload',
        "blockQuote",
        "undo",
        "redo"
        //'insertTable'
      ],
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    }
  }),
  computed: {
    ...mapState('author/database', ['filters', 'loadingFilter']),
    ...mapState('author/dashboard', ['processingProfileData']),
    
    subcategories () {
        const categories = _.cloneDeep(this.filters.categories || []);
        const data = [];
        categories.forEach(c => {
            (c.categories || []).forEach(
                cc => data.push({id: cc.id , text: cc.title , subtext: c.title})
            )
        });
        return data;
    },
    address () {
        return this.filters.cities || [];
    },
    
  },
  methods: { 
    ...mapActions('author/database', {
        loadFilters: 'loadFilters',
    }),
    ...mapActions('author/dashboard', ['updateProfileData']),
    ...mapActions('dashboard/festivalRequest', ['createOrUpdate',]),

    requestFestProfile() {
      this.createOrUpdate({data: this.req_fest_prof_file, token: this.$user.info}).then((data) => {
          if (!this.item.id) {

              if (data.success) {
                  this.req_fest_prof_file.id = null;
              } else if (data.data) {
                  this.req_fest_prof_file.id = data.data.id
              }
              
          }
      }).catch(() => {
          this.alerts = [ {
              success: false,
              message: 'Problema no processamento'
          }];
      });
    },

    updateProfile() {
      this.message = null;
      this.error = null;
      if (this.$refs.form.validate()) {
        this.updateProfileData({data: this.info, token: this.$user.info}).then((data) => {
          if (data.success) {
            this.message = data.message;
            this.info.photo = null;
            this.info.bio.cover = null;
          } else {
            this.error = data.message;
          }
          this.snackbar = true;
        });
      } else {
        /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
        //console.warn(this.$refs.form)
        alert('Favor validar o formulário')
      }
    },
    removeAddr() {
      this.info.city = null
    },
    removeCategory(category) {
      this.info.subcategories = (this.info.subcategories || []).filter(c => c !== category);
    },
  }
};
</script>