<template>
    <div>
        <v-container fluid v-if="author">
            <v-row justify="start">
                <v-col
                cols="12"
                md="12">
                <v-alert
                    color="primary"
                    border="left"
                    elevation="0"
                    colored-border
                    icon="mdi-account" class="secondary">
                    <v-btn :to="profileRoute" depressed color="primary"><span class="black--text">Perfil</span></v-btn> > Editar perfil
                </v-alert>
                </v-col>
            </v-row>

            <PersonalInfo :infoData="author.info" />
            <Contact :contactData="author.contacts" />
            <!--ProjectList :items="author.projects" /-->
        </v-container>
        <v-text-field v-else color="primary" loading disabled></v-text-field>
    </div>
</template>
<script>
import PersonalInfo from './PersonalInfo';
import Contact from './Contact';
//import ProjectList from './ProjectList';
import { PROFILE } from '../../../routes';

import { LOGIN } from '../../../routes';
import { mapState, mapActions } from 'vuex';

export default {
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: LOGIN}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    data: () => ({profileRoute: PROFILE}),
    components: {
        PersonalInfo,
        Contact,
       // ProjectList,
    },
    computed: {
        userId() {
            return this.$user.info.id;
        },
         ...mapState('author/dashboard', ['author', 'loading'])
    },
    methods: {
        ...mapActions('author/dashboard', ['detail']),
        fetchDetail() {
            this.detail({ id: this.userId});
        }
    },   
}
</script>