<template>
    <v-card class="pa-8 mt-8 secondary">
        <v-overlay :value="processingProfileContact">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        {{message}}
        <v-form
          ref="form">
            <v-card-text>
                <v-row class="mb-4" align="center">
                    <v-avatar color="grey" class="mr-4"></v-avatar>
                    <strong class="title">Contactos</strong>
                    <div class="flex-grow-1"></div>
                    <v-btn icon>
                    <v-icon>mdi-content-paste</v-icon>
                    </v-btn>
                </v-row>
            </v-card-text>

            <v-row>
                <v-col md="6" sm="6" xl="6">
                    <v-text-field dense
                    outlined
                    v-model="contact.phone"
                    :counter="20"
                    v-mask="mask"
                    prefix="+"
                    hint="2389099909"
                    persistent-hint
                    label="contato"
                    ></v-text-field>
                </v-col>

                <v-col md="6" sm="6" xl="6">
                    <v-text-field dense
                    v-model="contact.website"
                    outlined
                    :counter="60"
                    label="Website/Blog"
                    hint="www.example.com/page"
                    persistent-hint
                    prefix="https://"
                    ></v-text-field>
                </v-col>

                <v-col md="6" sm="6" xl="6">
                    <v-text-field dense
                    v-model="contact.facebook"
                    outlined
                    :counter="60"
                    label="Facebook"
                    hint="www.facebook.com/username"
                    prefix="https://"
                    persistent-hint
                    ></v-text-field>
                </v-col>

                <v-col md="6" sm="6">
                    <v-text-field dense
                    v-model="contact.instagram"
                    outlined
                    :counter="60"
                    label="Instagram"
                    hint="Instagram username"
                    persistent-hint
                    ></v-text-field>
                </v-col>

                <v-col md="6" sm="6" >
                    <v-text-field dense
                    v-model="contact.youtube"
                     prefix="https://"
                    outlined
                    :counter="60"
                    label="Youtube"
                    hint="Link canal de Youtube"
                    persistent-hint
                    ></v-text-field>
                </v-col>


                <v-col cols="12">
                    <v-btn 
                        :loading="processingProfileContact"
                        :disabled="processingProfileContact"
                        color="primary"
                        @click="updateContact"><span class="black--text">Guardar alteração de contacto</span></v-btn>
                </v-col>
                
                <!--v-col md="6" sm="6" xl="2">
                    <v-checkbox dense
                        v-if="contact.isHistorical"
                        v-model="contact.isHistorical"
                        color="success"
                        label="Perfil Historico"
                    ></v-checkbox>
                    <v-checkbox
                        v-else
                        v-model="contact.isHistorical"
                        color="error"
                        label="Perfil Historico"
                    ></v-checkbox>
                </v-col-->
            </v-row>
        </v-form>
        <v-snackbar
            v-model="snackbar"
            :color="message ? 'success' : 'error'"
            :multi-line="true"
            :left="true"
            :timeout="6000"
            :bottom="true"
            :vertical="true"
            >
            <p class="white--text">{{ message || error }}</p>
            <v-btn
                dark
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
//import { mapState } from 'vuex';
import { mask } from "vue-the-mask";

import _ from "lodash";
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    contactData: {
      type: Object,
    },
  },
  $_veeValidate: {
    validator: "new"
  },
  directives: {
    mask
  },
  created() {
      if (this.contactData) {
          this.contact = _.cloneDeep(this.contactData);
      }
  },
  data: () => ({
    mask: '### #################',
    snackbar: false,
    message: null,
    error: null,
    readonly: true,
    contact: {
        phone: null,
        instagram: null,
        //email: null,
        website: null,
        isHistorical: false,
        facebook: null,
        youtube: null,
    },
  }),
  computed: {
    ...mapState('author/dashboard', ['processingProfileContact']),
  },
  methods: {
    ...mapActions('author/dashboard', ['updateProfileContact']),

    updateContact() {
        this.message = null;
        this.error = null;
        if (this.$refs.form.validate()) {
            
            
            this.updateProfileContact({data: this.contact, token: this.$user.info}).then((data) => {
                if (data.success) {
                    this.message = data.message;
                } else {
                    this.error = data.error;
                }
                this.snackbar = true;
            });
            
        }
      
    },
  }
};
</script>